<template>
  <div class="hero" id="cabinHero" :style="{ 'background-image': `url(${cabinImage})` }">
    <div v-show="!canEdit" class="file-upload-container">
      <label for="file-upload" class="upload-image">
        <i class="fa fa-cloud-upload"></i> {{ uploadOrChangeImage }}
      </label>
      <input
        id="file-upload"
        type="file"
        @change="onFileChange($event)"
      />
    </div>
  </div>
</template>
<script>
import CabinService from '@/services/CabinService';

export default {
  name: 'CabinHero',
  props: {
    cabin: Object,
    cabinUserRoleId: Number
  },
  data() {
    return {
      fileSelected: null,
      cabinImage: false
    }
  },
  computed: {
    canEdit() {
      if((this.isCabinOwner() || this.isCabinAdministrator()) && this.$route.name === 'settings') return false;

      return true;
    },
    uploadOrChangeImage() {
      if(!this.cabinImage) {
        return 'Last opp bilde';
      }

      return 'Oppdater bilde';
    }
  },
  methods: {
    onFileChange(event) {
      const selectedImage = event.target.files[0];
      this.createImageAsBase64(selectedImage)

    },
    createImageAsBase64(fileObject) {
      const reader = new FileReader();
      let hasCabinImage;
      if(this.cabinImage) {
        hasCabinImage = true;
      } else {
        hasCabinImage = false;
      }
      reader.onload = (event) => {
        this.cabinImage = event.target.result;
        let splitImage = this.cabinImage.split(',');
        let savingFileObject = {
          name: fileObject.name,
          base64: splitImage[1]
        }
        if(hasCabinImage) {
          CabinService.updateCabinContent(this.cabin.content[0].id, 1, 5, '', savingFileObject.base64, savingFileObject.name, true, 1).then(res => {
            console.log('Successfully updated cabin content/image', res);
          }).catch(err => {
            console.log('Could not update cabin content/image', err)
          })
        } else {
          CabinService.addCabinContent(this.cabin.id, 1, 5, 'Test', savingFileObject.base64, savingFileObject.name, true, 1).then(res => {
              console.log('Successfully added cabin content/image', res);
            }).catch(err => {
              console.log('Could not add cabin content/image', err)
            })
        }
      }
      reader.readAsDataURL(fileObject);
    },
    isCabinOwner() {
      if (this.cabinUserRoleId === 1) return true;

      return false;
    },
    isCabinAdministrator() {
      if (this.cabinUserRoleId === 2) return true;

      return false;
    },
    isCabinUser() {
      if (this.cabinUserRoleId === 3) return true;

      return false;
    },
  },
  created() {
    if(this.cabin.content.length > 0) {
      this.cabinImage = this.cabin.content[0].storageUrl + this.cabin.storageTokenQuery;
    } else {
      this.cabinImage = false;
    }
  }
};
</script>
<style lang="scss" scoped>
.hero {
  position: relative;
  background-image: url('../assets/hytteHero.jpg');
  background-position: center;
  display: block;
  width: 100%;
  height: 0;
  padding-bottom: 70%;
  border-radius: 0px;
  margin-top: -87px;
  transition: padding 0.5s ease;
  @media (min-width: $breakpoint-tablet) {
    margin: 0 auto;
    max-width: 98%;
    padding-bottom: 30%;
    border-radius: 8px;
    margin-top: 0;
  }
  background-size: cover;
  z-index: 0;
  img {
    max-width: 100%;
  }
  .file-upload-container {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    input[type="file"] {
      display: none;
    }
    .upload-image {
      font-size: 13px;
      font-weight: 600;
      border-radius: 8px;
      padding: 0.75rem 1rem;
      background-color: $hytta;
      color: white;
      text-decoration: none;
      cursor: pointer;
    }
  }
}
@media (max-width: $breakpoint-tablet) {
  .hero.drawer-modal-active {
    padding-bottom: 22%;
  }
}

</style>
